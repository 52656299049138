.custom-modal {
  border-radius: 10px;
  padding: 20px 40px;
}

.react-responsive-modal-overlay {
  background: #e4e8f0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-modal {
  box-shadow: 0 0 10px 0 rgb(0 28 66 / 25%);
}