#search-app {
    width: 100%;
    height: 50px;
    padding-left: 15px;
    color: #18187A;
}

#search-app:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
    display: none;
}
